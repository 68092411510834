import { Grid } from "@mui/material";

import { FocalPointImage } from "../../../graphql/__generated/sdk";

import { getBannerImageUrl } from "./ODPHero.helpers";
import { mobileBrandBannerStyles } from "./ODPHero.styles";

interface BannerProps {
    bannerImages: FocalPointImage[];
    originalBrand: string;
}

export default function Banner({ bannerImages, originalBrand }: Readonly<BannerProps>) {

    const bannerImageUrl = getBannerImageUrl(bannerImages, originalBrand);

    if (!bannerImageUrl) {
        return null
    }

    return (
        <Grid data-test-id="section-odp-hero-banner" container sx={mobileBrandBannerStyles.containerStyles}>
            <img src={bannerImageUrl} alt="Banner" style={mobileBrandBannerStyles.imageStyles} />
        </Grid>
    );
}